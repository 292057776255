<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/b2b/main/home" @click="navigation('/b2b/main/home')">
          <ion-icon :icon="homeOutline" />
          <ion-label>{{ $t('tabs.home') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="order" href="/b2b/order" @click="navigation('/b2b/order')">
          <ion-icon :icon="bagHandleOutline" />
          <ion-label>{{ $t('tabs.orders') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="cart"
          href="/b2b/cart"
          @click="navigation('/b2b/cart')"
          class="position-relative"
        >
          <ion-icon :icon="cartOutline" />
          <div class="cart-badge" v-if="cartBadgeNumber">{{ cartBadgeNumber }}</div>
          <ion-label>{{ $t('tabs.cart') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="account" href="/b2b/account" @click="navigation('/b2b/account')">
          <ion-icon :icon="personOutline" />
          <ion-label>{{ $t('tabs.account') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  bagHandleOutline,
  cartOutline,
  homeOutline,
  personOutline,
  searchOutline,
  settingsOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

import { ACTIONS as ACTIONS_CART } from '@/modules/b2b/store/cart/actions';
import { ACTIONS as ACTIONS_CUSTOMER } from '@/modules/b2b/store/customer/actions';
import { getCartBadge, setBadgeNumber } from '@/modules/shared/utils/badge';
import { alertController } from '@ionic/vue';
import { computed, inject } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapGetters: mapGettersCustomer, mapActions: mapActionsCustomer } = createNamespacedHelpers(
  'b2b/customer'
);
const { mapActions: mapActionsCart } = createNamespacedHelpers('b2b/cart');

export default defineComponent({
  name: 'tabs',
  setup() {
    const storage = inject('$storage');
    const store = useStore();
    const ionRouter = inject('navManager');
    const selectedCompany = ref(null);
    const setSelectedCompany = async () => {
      selectedCompany.value = await storage.getSelectedCompany();
    };
    const user = ref(null);
    const setUser = async () => {
      user.value = await storage.getUser();
    };
    const cartBadgeNumber = computed(() => getCartBadge(store));

    return {
      store,
      storage,
      selectedCompany,
      setSelectedCompany,
      user,
      setUser,
      homeOutline,
      searchOutline,
      settingsOutline,
      personOutline,
      cartOutline,
      bagHandleOutline,
      ionRouter,
      cartBadgeNumber
    };
  },
  computed: {
    ...mapGettersCustomer(['selectedCustomer', 'customerTenants'])
  },
  async mounted() {
    await this.setUser();
    await this.setSelectedCompany();
    await this.getCustomerTenants();
    await setBadgeNumber(this.store, this.selectedCompany.id, this.user.tenant.id);
  },
  methods: {
    ...mapActionsCart([ACTIONS_CART.GET_CART_ITEMS]),
    ...mapActionsCustomer([ACTIONS_CUSTOMER.GET_CUSTOMER_TENANTS]),
    navigation(params) {
      this.ionRouter.navigate({
        routerDirection: 'none',
        routerLink: params
      });
    },

    async presentAlertNoConnectedTenant() {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        message: this.$t('no_connected_tenant_err'),
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('OK'),
            id: 'confirm-button'
          }
        ]
      });
      await alert.present();
    },

    async getCustomerTenants() {
      if (!this.user || !this.selectedCompany) return;
      if (!this.user.is_b2c || !this.selectedCompany.is_b2c) return;

      await this[ACTIONS_CUSTOMER.GET_CUSTOMER_TENANTS]({ customerId: this.selectedCompany.id });

      if (this.customerTenants.length >= 1) {
        const exist = this.customerTenants.some((tenant) => tenant.id === this.user.tenant.id);
        if (exist) return;

        await this.storage.setUserTenant(this.customerTenants[0]);
        window.location.href = '/b2b/main/home';
      } else if (this.customerTenants.length === 0) {
        await this.presentAlertNoConnectedTenant();
      }
    }
  },

  async ionViewDidEnter() {
    await this.getCustomerTenants();
  }
});
</script>

<style scoped>
.cart-badge {
  position: absolute;
  background-color: var(--ion-color-primary);
  color: white;
  top: 2px;
  right: 16%;
  width: 20px;
  height: 16px;
  border-radius: 5px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
